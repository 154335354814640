<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey">
			<v-flex class="font-level-3-bold">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/location-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Quantity by Location
			</v-flex>
			<v-flex class="text-right">
				<v-btn
					v-on:click="addStock()"
					color="blue darken-4 text-white"
					class="ml-2"
					:disabled="pageLoading"
					depressed
					tile
					><v-icon small left>mdi-playlist-plus</v-icon>Add Stock</v-btn
				>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 375px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 light-blue-bg">Location</th>
						<th class="p-2 light-blue-bg" width="30%">Available Quantity</th>
					</tr>
				</thead>
				<tbody v-if="locations.length">
					<tr v-for="(row, index) in locations" :key="index">
						<td class="p-2 border-top-light-grey">
							<p class="m-0 blue--text">{{ row.location_name }}</p>
						</td>
						<td class="p-2 border-top-light-grey" width="30%">{{ row.quantity_formatted }}</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="2">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no quantity by location at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import { EventBus } from "@/core/event-bus/event.bus";

export default {
	name: "quantity-location-template",
	title: "Quantity Location Template",
	props: {
		typeText: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			pageLoading: true,
			locations: [],
		};
	},
	methods: {
		addStock() {
			EventBus.$emit("add:stock", { type: this.type, typeUUID: this.typeUuid });
		},
		getLocations() {
			ApiService.get(`${this.type}/${this.typeUuid}/location`)
				.then(({ data }) => {
					this.locations = data;
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	beforeDestroy() {
		EventBus.$off("reload:quantity-by-location");
	},
	mounted() {
		this.getLocations();

		EventBus.$on("reload:quantity-by-location", () => {
			this.getLocations();
		});
	},
};
</script>
